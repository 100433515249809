<template>
  <div class="mb-4">
    <Confirm ref="confirm" />
    <v-row class="px-3 mt-1">
      <h2>
        {{ $t('alertStatus.heading') }}
        <!-- <v-icon v-if="!shouldStopPolling" color="grey" class="progress-circle" right size="20">
          fal fa-spinner fa-pulse
        </v-icon> -->
      </h2>
      <v-spacer />
      <template v-if="alertStatus">
        <v-chip
          v-for="item in alertStatus.operatorStatuses"
          :key="item.name"
          class="ml-2 py-4"
          outlined
          text-color="black"
        >
          <v-avatar left>
            <v-img contain :src="operatorLogo(item.operator)" />
          </v-avatar>
          <span class="capitalize-first-letter mr-2">{{ item.operator }}</span>
          <template v-if="item.finished">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon color="green" class="text-center" right size="20" v-bind="attrs" v-on="on">
                  fal fa-check-circle
                </v-icon>
              </template>
              <span>{{ $t('alertStatus.operatorFinished', { name: item.operator }) }} </span>
            </v-tooltip>
          </template>
          <template v-else>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  class="text-center pulse"
                  right
                  size="20"
                  v-bind="attrs"
                  v-on="on"
                >
                  fal fa-info-circle
                </v-icon>
              </template>
              <span>{{ $t('alertStatus.operatorNotFinished', { name: item.operator }) }}.</span
              ><br />
              <span>
                {{ $t('alertStatus.lastStatusUpdate') }}
                {{ $d(new Date(alertStatus.lastUpdated), 'shortDate') }} {{ $t('timePrefix') }}
                {{ $d(new Date(alertStatus.lastUpdated), 'timeWithSeconds') }}</span
              >
            </v-tooltip>
          </template>
        </v-chip>
      </template>
    </v-row>
    <div v-if="!alertHasStarted">
      <v-row>
        <v-col>
          <div class="text-center">
            <i class="fal fa-clock empty-state-icon ma-8" />
            <h2>{{ $t('alertStatus.notStarted') }}</h2>
            <template v-if="alertInfo">
              <p v-if="isToday(alertInfo.start)">
                {{ $t('alertStatus.alertWillStartAt') }}
                {{ $d(new Date(alertInfo.start), 'time') }}.
              </p>
              <p v-else>
                {{ $t('alertStatus.alertWillStart') }}
                {{ $d(new Date(alertInfo.start), 'longDate') }} {{ $t('timePrefix') }}
                {{ $d(new Date(alertInfo.start), 'time') }}
              </p>
            </template>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div v-if="alertStatus && alertStatus.byCountry">
        <!-- <v-row class="pt-2 pl-3">
          <span class="font-weight-medium pt-1">{{ $t('alertStatus.lastStatusUpdate') }} 
            {{ $d(new Date(alertStatus.lastUpdated), 'shortDate') }} {{ $t('timePrefix') }} {{ $d(new Date(alertStatus.lastUpdated), 'timeWithSeconds') }}
          </span>
        </v-row> -->

        <v-alert
          v-if="showStatusUpdateInfo"
          type="info"
          dense
          text
          icon="fal fa-info-circle"
          class="mt-4 mb-2"
        >
          {{ $t('alertStatus.statusUpdateInfo') }}
        </v-alert>
        <v-alert
          v-if="alertInfo && alertInfo.submissionMode == 2"
          dense
          text
          type="warning"
          class="my-4"
        >
          {{ $t('alertStatus.simulatedResultDisclaimer') }}
        </v-alert>
        <v-row v-if="alertStatus.deliveryStatus">
          <v-col>
            <div class="summaryCounter">
              <v-icon class="countup-pre-text-icon">fal fa-users</v-icon>
              <ICountUp
                class="counter no-wrap"
                :delay="countupDelay"
                :end-val="alertStatus.deliveryStatus.total"
                :options="countupOptions"
              />
              <span>{{ $t('alertStatus.totalRecipients') }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!alertStatus" justify="center" align="center">
          <v-col cols="12" md="6" class="pa-0">
            <v-skeleton-loader type="avatar" />
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <v-skeleton-loader type="list-item-three-line" />
          </v-col>
        </v-row>
        <v-row v-else>
          <template v-if="!alertInfo.isRecurring">
            <h3 class="mx-3">{{ $t('alertStatus.progress') }}</h3>
            <v-col cols="12" class="pt-0 pb-4">
              <b-progress show-progress max="100">
                <b-progress-bar
                  class="processed"
                  :value="$store.getters['alertStatus/getTotalProcessedPercent']"
                  :label="`${$store.getters['alertStatus/getTotalProcessedPercent']}%`"
                  aria-label="Finished"
                >
                  <span
                    >{{ $t('alertStatus.finishedProccessing') }}
                    {{ $store.getters['alertStatus/getTotalProcessedPercent'] }}%</span
                  >
                </b-progress-bar>
              </b-progress>
            </v-col>
          </template>
          <h3 class="mx-3">{{ $t('alertStatus.smsDispatch') }}</h3>
          <v-col cols="12" class="pt-0 pb-4">
            <b-progress show-progress max="100">
              <b-progress-bar
                class="success"
                :value="$store.getters['alertStatus/getTotalDeliveredSmsPercent']"
                :label="`${$store.getters['alertStatus/getTotalDeliveredSmsPercent']}%`"
                aria-label="Success"
              />
              <b-progress-bar
                class="failed"
                :value="$store.getters['alertStatus/getTotalFailedSmsPercent']"
                :label="`${$store.getters['alertStatus/getTotalFailedSmsPercent']}%`"
                aria-label="Failed"
              />
              <b-progress-bar
                class="unknown"
                :value="$store.getters['alertStatus/getTotalUnknownSmsPercent']"
                :label="`${$store.getters['alertStatus/getTotalUnknownSmsPercent']}%`"
                aria-label="Unknown"
              />
              <b-progress-bar
                class="processing"
                :value="$store.getters['alertStatus/getTotalProcessingPercent']"
                :label="`${$store.getters['alertStatus/getTotalProcessingPercent']}%`"
                aria-label="Processing"
              />
            </b-progress>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <tbody>
                  <tr>
                    <td width="50px"><v-avatar left size="20" class="avatar success" /></td>
                    <td>{{ $t('alertStatus.delivered') }}</td>
                    <td class="text-right no-wrap">
                      {{ alertStatus.deliveryStatus.deliveredSuccessfully | separator }}
                    </td>
                    <td class="text-right">
                      {{ $store.getters['alertStatus/getTotalDeliveredSmsPercent'] }}%
                    </td>
                  </tr>
                  <tr>
                    <td><v-avatar left size="20" class="avatar failed" /></td>
                    <td>{{ $t('alertStatus.failed') }}</td>
                    <td class="text-right no-wrap">
                      {{ alertStatus.deliveryStatus.deliveryFailed | separator }}
                    </td>
                    <td class="text-right">
                      {{ $store.getters['alertStatus/getTotalFailedSmsPercent'] }}%
                    </td>
                  </tr>
                  <tr>
                    <td><v-avatar left size="20" class="avatar unknown" /></td>
                    <td>{{ $t('alertStatus.unknown') }}</td>
                    <td class="text-right no-wrap">
                      {{ alertStatus.deliveryStatus.deliveryUnknown | separator }}
                    </td>
                    <td class="text-right">
                      {{ $store.getters['alertStatus/getTotalUnknownSmsPercent'] }}%
                    </td>
                  </tr>
                  <tr>
                    <td><v-avatar left size="20" class="avatar processing" /></td>
                    <td>{{ $t('alertStatus.processing') }}</td>
                    <td class="text-right no-wrap">
                      {{ alertStatus.deliveryStatus.processing | separator }}
                    </td>
                    <td class="text-right">
                      {{ $store.getters['alertStatus/getTotalProcessingPercent'] }}%
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <!-- <v-col cols="12">
          <v-chip
            class="mr-2"
            color="#bcd8bb"
            outlined
            text-color="black"
          >
            <v-avatar
              left
              class="avatar success"
            />
            Levert {{ alertStatus.status.deliveredSuccessfully | separator }}
          </v-chip>
          <v-chip
            class="mr-2"
            color="#e49e9e"
            outlined
            text-color="black"
          >
            <v-avatar
              left
              class="avatar failed"
            />
            Mislykket {{ alertStatus.status.deliveryFailed | separator }}
          </v-chip>
          <v-chip
            class="mr-2"
            color="#fbd595"
            outlined
            text-color="black"
          >
            <v-avatar
              left
              class="avatar unknown"
            />
            Ukjent {{ alertStatus.status.deliveryUnknown | separator }}
          </v-chip>
          <v-chip
            color="#e4e4e4"
            outlined
            text-color="black"
          >
            <v-avatar
              left
              class="avatar processing"
            />
            Pågår {{ alertStatus.status.processing | separator }}
          </v-chip>
        </v-col> -->
        </v-row>
        <template
          v-if="alertStatus && alertStatus.byCountry && alertStatus.byCountry.hasPrivacyViolation"
        >
          <v-row>
            <v-col cols="12" class="text-center">
              <v-alert text type="warning"> {{ $t('alertStatus.privacyDisclaimer') }} </v-alert>
            </v-col>
          </v-row>
        </template>
        <v-expansion-panels v-else v-model="statusByCountryExpanded" flat class="pt-4">
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-0">
              <h3>{{ $t('alertStatus.smsNationalityBased') }}</h3>
              <template #actions>
                <span class="text-caption pa-2">{{ $t('alertStatus.details') }}</span>
                <v-icon> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-0">
              <v-skeleton-loader
                v-if="!alertStatus && !alertStatus.byCountry"
                type="list-item-avatar@3"
              />
              <v-data-table
                v-if="alertStatus && alertStatus.byCountry"
                :headers="countryStatusListHeaders"
                :items="alertStatus.byCountry.countryStatuses"
                flat
                :items-per-page="5"
                :footer-props="{
                  itemsPerPageText: $t('rowCount'),
                  pageText: '{0}-{1} ' + $t('of') + ' {2}',
                  itemsPerPageAllText: $t('all'),
                }"
              >
                <template #[`item.country.name`]="{ item }">
                  <div v-if="item.country" class="d-flex align-center">
                    <rounded-flag :country="item.country.code" size="40px" />
                    <span v-if="item.country.name === 'rest'" class="ml-4">{{
                      $t('alertStatus.unknownNationality')
                    }}</span>
                    <span v-else class="ml-4">{{ item.country.name }}</span>
                  </div>
                </template>
                <template #[`item.deliveryStatus`]="{ item }">
                  <div v-if="item.deliveryStatus" class="d-flex align-center">
                    <b-progress
                      show-value
                      :max="item.deliveryStatus.total - item.deliveryStatus.removed"
                    >
                      <b-progress-bar
                        class="success"
                        :value="item.deliveryStatus.deliveredSuccessfully"
                        aria-label="Success"
                      />
                      <b-progress-bar
                        class="failed"
                        :value="item.deliveryStatus.deliveryFailed"
                        aria-label="Failed"
                      />
                      <b-progress-bar
                        class="unknown"
                        :value="item.deliveryStatus.deliveryUnknown"
                        aria-label="Unknown"
                      />
                      <b-progress-bar
                        class="processing"
                        :value="item.deliveryStatus.processing"
                        aria-label="Processing"
                      />
                    </b-progress>
                  </div>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div v-else>
        <v-alert text color="orange">
          <v-progress-circular indeterminate color="orange" class="mr-4" />{{
            $t('alertStatus.gettingAlertStatuses')
          }}
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import ICountUp from 'vue-countup-v2';
import RoundedFlag from '@/components/RoundedFlag.vue';
import Confirm from '@/components/dialog/Confirm.vue';
import { t } from '@/plugins/i18n';
import moment from 'moment';

export default {
  name: 'AlertStatus',
  components: {
    ICountUp,
    RoundedFlag,
    Confirm,
  },
  props: {
    alertId: {
      type: String || Number,
      default: '',
    },
  },
  data: () => ({
    loading: true,
    countupDelay: 0,
    countupOptions: {
      useEasing: true,
      useGrouping: true,
      separator: ' ',
      decimal: '.',
      prefix: '',
      suffix: '',
    },
    statusPolling: null,
  }),
  computed: {
    alertHasStarted() {
      return this.alertInfo && moment(this.alertInfo.start).isBefore(moment());
    },
    showStatusUpdateInfo() {
      if (!this.alertStatus) return false;

      if (this.alertStatus.finished) return false;

      return this.alertHasStarted;
    },
    countryStatusListHeaders() {
      return [
        { text: t('alertStatus.nationality'), value: 'country.name' },
        { text: t('alertStatus.progress'), value: 'deliveryStatus', width: '70%' },
      ];
    },
    alertStatus() {
      return this.$store.state.alertStatus.status;
    },
    shouldStopPolling() {
      if (!this.alertHasStarted) {
        return true;
      }
      if (this.alertStatus?.finished) {
        return true;
      }
      return false;
    },
    statusByCountryExpanded: {
      get() {
        return this.$store.state.user.statusByCountryExpanded;
      },
      set(value) {
        this.$store.commit('user/setStatusByCountryExpanded', value);
      },
    },
    alertInfo() {
      return this.$store.state.alertStatus.info;
    },
  },
  watch: {
    alertInfo() {
      if (this.alertHasStarted) {
        this.getStatus(this.alertId);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.statusPolling);
  },
  methods: {
    getStatus(alertId) {
      this.loading = true;
      this.$store
        .dispatch('alertStatus/getStatus', alertId)
        .then(() => {
          this.loading = false;
          if (this.alertStatus.deliveryStatus.processing >= 0) {
            this.pollData();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    pollData() {
      if (this.shouldStopPolling) {
        return;
      } else {
        let pollingInterval = this.getPollingInterval();
        this.statusPolling = setTimeout(() => {
          this.getStatus(this.alertId);
        }, pollingInterval);
      }
    },
    getPollingInterval() {
      let interval = 10;
      let progress = this.$store.getters['alertStatus/getTotalProcessedPercent'];
      if (progress < 10) interval = 5;
      else if (progress < 25) interval = 5;
      else if (progress < 50) interval = 5;
      else if (progress < 75) interval = 5;
      else if (progress < 90) interval = 5;
      else interval = 15;

      return interval * 1000;
    },
    stopPoll() {
      clearInterval(this.statusPolling);
    },
    isToday(startDate) {
      let d = new Date();
      let sd = new Date(startDate);
      return d.toDateString() === sd.toDateString();
    },
    operatorLogo: function (operator) {
      operator = operator.toLowerCase();
      if (operator === 'telenor') return require(`@/assets/images/telenor.png`);
      if (operator === 'telia') return require(`@/assets/images/telia.png`);
      if (operator === 'ICE') return require(`@/assets/images/ice.svg`);

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.iCountUp {
  font-size: 2em;
  margin: 0 -1px;
  padding: 16px 16px;
  border: 1px solid #e6e6e6;
}
.summaryCounter {
  margin: 0 -1px;
  padding: 16px 16px;
  border: 1px solid #e6e6e6;
  text-align: center;
  .counter {
    font-size: 2em;
  }
  p {
    color: '#7d7d7d';
  }
}
.deliveryChart {
  max-width: 250px;
  margin: auto;
}
.countryStatusChart {
  max-height: 25px;
  margin: auto;
}
.progress {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  height: 25px;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  border: 1px solid #cacaca;
}
.progress-bar {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #242424;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
  &.success {
    background-color: #bcd8bb !important;
  }
  &.failed {
    background-color: #e49e9e !important;
  }
  &.unknown {
    background-color: #fbd595 !important;
  }
  &.processing {
    background-color: #e4e4e4 !important;
  }
  &.processed {
    background-color: #457b9d !important;
    color: white;
  }
}
.avatar {
  &.success {
    background-color: #bcd8bb !important;
    border: 1px solid #60b95c;
  }
  &.failed {
    background-color: #e49e9e !important;
    border: 1px solid #c93232;
  }
  &.unknown {
    background-color: #fbd595 !important;
    border: 1px solid #ffa710;
  }
  &.processing {
    background-color: #e4e4e4 !important;
    border: 1px solid #a7a7a7;
  }
}
.v-expansion-panels ::v-deep {
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
.countup-pre-text-icon {
  font-size: 30px;
  margin: -14px 8px 0px 0;
  padding: 0px 0px 0px 0;
}
.progress-circle {
  float: right;
  margin-right: 15px;
}
.pulse {
  animation: pulse-opacity 2s infinite;
}
@keyframes pulse-opacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
</style>
>
