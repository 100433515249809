<template>
  <div>
    <Confirm ref="confirm" />
    <v-progress-linear v-if="!alertInfo" :indeterminate="true" />
    <div>
      <h2 class="ml-1">{{ $t('alertInfo.heading') }}</h2>
      <div v-if="alertInfo">
        <v-row class="pt-2 pl-3">
          <span class="font-weight-medium pt-1 ml-1"
            >{{ $t('alertInfo.created') }} {{ $d(new Date(alertInfo.created), 'shortDate') }}
            {{ $t('timePrefix') }} {{ $d(new Date(alertInfo.created), 'time') }}
            {{ $t('alertInfo.by') }} {{ alertInfo.createdByUsername }}
          </span>
        </v-row>
        <v-row v-if="alertInfo" class="mt-1">
          <v-col cols="12" align-self="center">
            <template v-if="status == 'active'">
              <v-row>
                <v-col cols="12" lg="6">
                  <div class="status-badge status-active">{{ $t('alertInfo.alertIsActive') }}</div>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-btn rounded color="third" dark block @click="stopAlert">{{
                    $t('alertInfo.finishAlert')
                  }}</v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-else-if="status == 'planned'">
              <v-row>
                <v-col cols="12" lg="6">
                  <div class="status-badge status-active">
                    {{ $t('alertInfo.alertNotStarted') }}
                  </div>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-btn rounded color="third" dark block @click="stopAlert">{{
                    $t('alertInfo.stopAlert')
                  }}</v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-else-if="status == 'closed'">
              <div class="status-badge status-closed">{{ $t('alertInfo.alertIsFinished') }}</div>
            </template>
            <template v-else-if="status == 'cancelled'">
              <div class="status-badge status-cancelled">
                {{ $t('alertInfo.alertIsCancelled') }} {{ $t('alertInfo.by') }}
                {{ alertInfo.cancelledByUsername }} <br />
                {{ $d(new Date(alertInfo.cancelled), 'shortDate') }}
                {{ $t('timePrefix') }} {{ $d(new Date(alertInfo.cancelled), 'time') }}
              </div>
            </template>
          </v-col>
          <v-col v-if="alertInfo.isRecurring && status == 'active'" cols="12" align-self="center">
            <vac :end-time="new Date(alertInfo.end).getTime()">
              <template #process="{ timeObj }">
                <div class="status-badge status-active">
                  <span>{{ $t('alertInfo.remainingTime') }}</span>
                  <span v-if="timeObj.d > 0">{{ `${timeObj.d}` }} {{ $t('alertInfo.days') }}</span>
                  <span v-if="timeObj.h > 0"
                    >{{ ` ${timeObj.h} ` }}{{ $t('alertInfo.hours') }}</span
                  >
                  <span v-if="timeObj.m > 0"
                    >{{ ` ${timeObj.m} ` }} {{ $t('alertInfo.minutes') }}</span
                  >
                  <span>{{ ` ${timeObj.s} ` }}{{ $t('alertInfo.seconds') }}</span>
                </div>
              </template>
              <template #finish />
            </vac>
          </v-col>
          <v-col cols="12" lg="6" class="py-2">
            <v-icon class="pre-text-icon">fal fa-clock</v-icon>{{ $t('alertInfo.start') }}
            {{ $d(new Date(alertInfo.start), 'shortDate') }} {{ $t('timePrefix') }}
            {{ $d(new Date(alertInfo.start), 'time') }}
          </v-col>
          <v-col cols="12" lg="6" class="py-2">
            <template v-if="alertInfo.isRecurring">
              <v-icon class="pre-text-icon">fal fa-clock</v-icon>{{ $t('alertInfo.end') }}
              {{ $d(new Date(alertInfo.end), 'shortDate') }} {{ $t('timePrefix') }}
              {{ $d(new Date(alertInfo.end), 'time') }}
            </template>
          </v-col>

          <v-col cols="12" lg="6" class="py-2">
            <template v-if="alertInfo.isRecurring">
              <v-icon class="pre-text-icon">fal fa-repeat</v-icon
              >{{ $t('alertInfo.alertNewInArea') }}
            </template>
            <template v-else>
              <span class="fa-stack fa-2x pre-text-icon">
                <i class="fal fa-slash fa-stack-1x" />
                <i class="fal fa-repeat fa-stack-1x" /> </span
              >{{ $t('alertInfo.doNotAlertNewInArea') }}
              <!-- <v-icon class="pre-text-icon" color="red">fal fa-repeat</v-icon>Varsler IKKE nye i området -->
            </template>
          </v-col>
          <v-col cols="12" lg="6" align-self="center" class="py-2">
            <v-icon class="pre-text-icon">fal fa-users</v-icon
            >{{ $t('alertInfo.estimatedSubscribers') }}
            {{ alertInfo.estimatedSubscriberCount | separator }}
          </v-col>
          <v-col
            v-if="alertInfo.includeList"
            cols="12"
            lg="6"
            align-self="center"
            class="py-2 d-flex align-center"
          >
            <v-icon class="pre-text-icon">fal fa-address-book</v-icon
            >{{ $t('alertInfo.includeList') }}:
            <v-btn text small @click="openIncludeList">{{ alertInfo.includeList.name }}</v-btn>
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="12" align-self="center">
            <v-icon class="pre-text-icon">fal fa-comment-alt-lines</v-icon>
            <p class="defaultMessage">{{ alertInfo.defaultMessage }}</p>
            <v-expansion-panels v-model="viewCountryMessagesExpanded" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="expansionHeader">
                  <p>
                    <v-icon left class="mr-6">fal fa-globe-europe</v-icon
                    >{{
                      $t('alertInfo.countrySpecificMessagesCount', {
                        count: alertInfo.messages.length,
                      })
                    }}
                  </p>
                  <template #actions>
                    <span class="text-caption pa-2">{{ $t('alertInfo.details') }}</span>
                    <v-icon> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0">
                  <v-data-table
                    v-if="alertInfo.messages"
                    :headers="countryMessagesListHeaders"
                    :items="alertInfo.messages"
                    :items-per-page="5"
                    :footer-props="{
                      itemsPerPageText: $t('rowCount'),
                      pageText: '{0}-{1} ' + $t('of') + ' {2}',
                      itemsPerPageAllText: $t('all'),
                    }"
                  >
                    <template slot="no-data">
                      {{ $t('alertInfo.noCountrySpesificMessagesAdded') }}
                    </template>
                    <template #[`item.countryCode`]="{ item }">
                      <div v-if="item.countryCode" class="d-flex align-center">
                        <rounded-flag :country="item.countryCode" size="35px" />
                        <span class="ml-4">{{ item.countryCode }}</span>
                      </div>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog v-if="alertInfo && alertInfo.includeList" v-model="includeListDialog" max-width="600">
      <v-card>
        <v-card-title>
          <v-icon class="pre-text-icon modal">fal fa-address-book</v-icon>
          <span class="headline">{{ alertInfo.includeList.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="includeListHeaders"
            :items="alertInfo.includeList.subscribers"
            :items-per-page="5"
            :footer-props="{
              itemsPerPageText: $t('rowCount'),
              pageText: '{0}-{1} ' + $t('of') + ' {2}',
              itemsPerPageAllText: $t('all'),
            }"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="includeListDialog = false">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { convertFromDomainToLeafletFormat } from '@/util/polygonConverter';
import RoundedFlag from '@/components/RoundedFlag.vue';
import Confirm from '@/components/dialog/Confirm.vue';

export default {
  name: 'AlertInfo',
  components: {
    RoundedFlag,
    Confirm,
  },
  props: {
    alertId: {
      type: String || Number,
      default: '',
    },
  },
  data: (self) => ({
    loading: true,
    center: {
      lat: 0,
      lng: 0,
    },
    drawTools: false,
    predefinedArea: null,
    percentMarkers: [],
    mapIsReady: false,
    alertHasStarted: false,
    viewCountryMessagesExpanded: false,
    includeListDialog: false,
    includeListHeaders: [
      { text: self.$t('name'), value: 'name' },
      { text: self.$t('phoneNumber'), value: 'msisdn', align: 'right' },
    ],
  }),

  computed: {
    alertInfo() {
      return this.$store.state.alertStatus.info;
    },
    alertStatus() {
      return this.$store.state.alertStatus.status;
    },
    alertStatusByCell() {
      return this.$store.state.alertStatus?.byCell;
    },
    status() {
      if (this.alertInfo.isCancelled) {
        return 'cancelled';
      }
      if (new Date(this.alertInfo.start) > Date.now()) {
        return 'planned';
      }
      if (!this.alertInfo.end) {
        return 'active';
      }
      if (new Date(this.alertInfo.end) < Date.now()) {
        return 'closed';
      } else {
        if (!this.alertInfo.isRecurring) {
          let progress = this.$store.getters['alertStatus/getTotalProcessedPercent'];
          if (progress < 100) {
            return 'active';
          } else {
            return 'closed';
          }
        }
        return 'active';
      }
    },
    countryMessagesListHeaders() {
      return [
        { text: this.$t('alertInfo.nationality'), value: 'countryCode' },
        { text: this.$t('alertInfo.message'), value: 'content' },
      ];
    },
  },
  watch: {
    alertStatusByCell() {
      if (this.alertStatusByCell) {
        const tempMarkers = [];
        for (let i = 0; i < this.alertStatusByCell.cellStatuses.length; i++) {
          const marker = {
            latLng: {
              lat: this.alertStatusByCell.cellStatuses[i].latitude,
              lng: this.alertStatusByCell.cellStatuses[i].longitude,
            },
            id: i,
            total: this.alertStatusByCell.cellStatuses[i].total,
            delivered: this.alertStatusByCell.cellStatuses[i].delivered,
          };
          tempMarkers.push(marker);
        }
        this.percentMarkers = tempMarkers;
      }
    },
    alertInfo() {
      if (new Date(this.alertInfo.start) <= Date.now()) {
        this.alertHasStarted = true;
        if (this.mapIsReady) {
          let converted = convertFromDomainToLeafletFormat(this.alertInfo.multiPolygon);
          this.predefinedArea = converted;
        }
      } else {
        this.alertHasStarted = false;
        if (this.mapIsReady) {
          let converted = convertFromDomainToLeafletFormat(this.alertInfo.multiPolygon);
          this.predefinedArea = converted;
        }
      }
    },
  },

  methods: {
    mapReady() {
      this.mapIsReady = true;
      if (this.alertInfo) {
        let converted = convertFromDomainToLeafletFormat(this.alertInfo.multiPolygon);
        this.predefinedArea = converted;
      }
    },
    goTo(latLng) {
      this.center = latLng;
    },
    operatorLogo: function (operator) {
      if (operator === 'telenor') return require(`@/assets/images/telenor.png`);
      if (operator === 'telia') return require(`@/assets/images/telia.png`);
      if (operator === 'ICE') return require(`@/assets/images/ice.svg`);

      return '';
    },
    stopAlert() {
      this.$refs.confirm
        .open(
          this.$t('alertInfo.cancelAlertHeading'),
          this.$t('alertInfo.cancelAlertDescription'),
          this.$t('alertInfo.yesCancelAlert'),
          this.$t('alertInfo.noCancelAlert'),
          { color: 'third', width: '400px' },
        )
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('alertStatus/cancelAlert', this.alertId);
          }
          return;
        });
      return;
    },
    openIncludeList() {
      this.includeListDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.sending-mode-badge {
  padding: 5px 16px;
  text-align: center;
  border-radius: 19px;
  max-width: 300px;

  &.status-live {
    background-color: #f7cccc;
    border: 1px solid #ef9a9a;
    color: #670303;
  }

  &.status-simulate {
    background-color: #ffdfb8;
    border: 1px solid #ffc175;
    color: #703e00;
  }
}
.status-badge {
  padding: 7px 16px;
  text-align: center;
  border-radius: 19px;

  &.status-active {
    background-color: #ffdfb8;
    border: 1px solid #ffc175;
    color: #703e00;
  }

  &.status-closed {
    background-color: #dddddd;
    border: 1px solid #bebfbe;
    color: #484848;
  }
  &.status-cancelled {
    background-color: #ffdfb8;
    border: 1px solid #ffc175;
    color: #703e00;
  }
}
.missing-cell-info {
  bottom: 24px;
  position: absolute;
  max-width: 400px;
  background-color: #e5f5ff;
  left: 32px;
  z-index: 200;
  border-radius: 8px !important;
  padding: 8px;
  border: #457b9d 1px solid;
}
.v-tooltip__content {
  color: black;
}

.pre-text-icon {
  width: 32px;
  margin: 0px 16px 2px 0;
  color: #0000008a;
  font-size: 25px;
  &.modal {
    margin-left: 10px;
  }
}
.defaultMessage {
  padding-left: 50px;
  margin-top: -28px;
  min-height: 40px;
}
.country-msg-button {
  margin-left: 44px;
}
.v-expansion-panels ::v-deep {
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
.expansionHeader {
  padding-left: 0px;
  padding-top: 0px;
  p {
    margin: 0px 3px;
  }
}
</style>
