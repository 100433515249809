<template>
  <v-container full-height fluid>
    <v-row dense>
      <!--Heading -->
      <v-col cols="12"
        ><v-card class="pa-4">
          <v-container v-if="alertInfo" fluid>
            <v-row class="px-2">
              <v-btn icon color="primary" class="mr-4" aria-label="back" @click="navigateBack">
                <v-icon large>fal fa-arrow-circle-left</v-icon>
              </v-btn>
              <h2>{{ alertInfo.name }}</h2>
              <v-spacer />
              <v-tooltip v-if="alertInfo.submissionMode === 1" left color="#ef9a9a">
                <template #activator="{ on, attrs }">
                  <div class="sending-mode-badge status-live" v-bind="attrs" v-on="on">
                    {{ $t('alertLog.live') }}
                  </div>
                </template>
                <span class="body-1 text-live-dark">{{ $t('alertLog.liveDescription') }}</span>
              </v-tooltip>
              <v-tooltip v-else left color="#ffc175">
                <template #activator="{ on, attrs }">
                  <div v-bind="attrs" class="sending-mode-badge status-simulate" v-on="on">
                    {{ $t('alertLog.simulated') }}
                  </div>
                </template>
                <span class="body-1 text-simulate-dark">{{
                  $t('alertLog.simulatedDescription')
                }}</span>
              </v-tooltip>
              <!-- <template v-if="alertStatus">
            
              <v-chip
                v-for="item in alertStatus.operatorStatuses"
                :key="item.name"
                class="ml-2 py-4"
                outlined
                text-color="black"
              >
                <v-avatar
                  left
                >
                  <v-img
                    contain
                    :src="operatorLogo(item.operator)"
                  />
                </v-avatar>
                <span class="capitalize-first-letter mr-2">{{ item.operator }}</span>
                <template v-if="item.finished">
                  <v-icon color="green" class="text-center" right size="20">
                    fal fa-check-circle
                  </v-icon>
                </template>
                <template v-else>
                  <v-icon color="grey" class="text-center" right size="20">
                    fal fa-spinner fa-pulse
                  </v-icon>
                </template>
              </v-chip>
            </template> -->
            </v-row>
          </v-container>
          <v-container v-else fluid>
            <v-row>
              <v-btn icon color="primary" class="mr-4" @click="navigateBack">
                <v-icon large>fal fa-arrow-circle-left</v-icon>
              </v-btn>
              <h2>{{ $t('alertLog.noInformation') }}</h2>
              <v-spacer />
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="full-height-row">
      <v-col cols="12" md="6" xl="4">
        <v-card height="100%" class="pa-4"
          ><!-- AlertInfo -->
          <alert-info :alert-id="alertId" />
        </v-card>
      </v-col>
      <v-col cols="12" md="6" xl="4">
        <v-card height="100%" class="pa-4">
          <!-- AlertStatus -->
          <alert-status :alert-id="alertId" />
        </v-card>
      </v-col>

      <v-col cols="12" md="12" xl="4">
        <v-card width="100%" min-height="30vh" height="100%">
          <!-- Map -->
          <Map
            class="pa-0 ma-0"
            :rounded-corners="true"
            :center="center"
            :draw-tools="drawTools"
            :percent-markers="percentMarkers"
            height="100%"
            map-type="streets"
            :predefined-area="predefinedArea"
            :fullscreen-control="true"
            @ready="mapReady"
          />
          <template v-if="alertStatus && alertStatus.byCell">
            <div v-if="alertStatus.byCell.cellStatuses.length > 0" class="missing-cell-info">
              Leveranse-statistikk vises kun for utsending til Telenor-abonnenter.
            </div>
            <div
              v-else-if="alertStatus.byCell.hasPrivacyViolation"
              class="missing-cell-info privacy-violation"
            >
              {{ $t('alertDetails.privacyDisclaimer') }}
            </div>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <Confirm ref="confirm" />
  </v-container>
</template>

<script>
import Confirm from '@/components/dialog/Confirm.vue';
import Map from '@/components/Map';
import { convertFromDomainToLeafletFormat } from '@/util/polygonConverter';
import AlertInfo from '@/components/AlertInfo.vue';
import AlertStatus from '@/components/AlertStatus.vue';
export default {
  name: 'AlertDetails',
  components: {
    Confirm,
    Map,
    AlertInfo,
    AlertStatus,
  },
  data: () => ({
    alertId: '',
    center: {
      lat: 0,
      lng: 0,
    },
    drawTools: false,
    loading: true,
    predefinedArea: null,
    percentMarkers: [],
    mapIsReady: false,
    alertHasStarted: false,
    countryMessagesListHeaders: [
      { text: 'Nasjonalitet', value: 'countryCode' },
      { text: 'Melding', value: 'content' },
      // { text: 'Count', value: 'status.total', align:'right' }
    ],
    viewCountryMessagesExpanded: false,
  }),
  computed: {
    alertInfo() {
      return this.$store.state.alertStatus.info;
    },
    alertStatus() {
      return this.$store.state.alertStatus.status;
    },
  },
  watch: {
    alertStatus() {
      const tempMarkers = [];
      if (this.alertStatus?.byCell?.cellStatuses) {
        for (let i = 0; i < this.alertStatus.byCell.cellStatuses.length; i++) {
          const marker = {
            latLng: {
              lat: this.alertStatus.byCell.cellStatuses[i].latitude,
              lng: this.alertStatus.byCell.cellStatuses[i].longitude,
            },
            id: i,
            total: this.alertStatus.byCell.cellStatuses[i].total,
            delivered: this.alertStatus.byCell.cellStatuses[i].delivered,
          };
          tempMarkers.push(marker);
        }
        this.percentMarkers = [];
        setTimeout(() => {
          this.percentMarkers = tempMarkers;
        }, 0);
      }
    },
    alertInfo() {
      if (this.alertInfo) {
        if (new Date(this.alertInfo.start) <= Date.now()) {
          this.alertHasStarted = true;
          if (this.mapIsReady) {
            let converted = convertFromDomainToLeafletFormat(this.alertInfo.multiPolygon);
            this.predefinedArea = converted;
          }
        } else {
          this.alertHasStarted = false;
          if (this.mapIsReady) {
            let converted = convertFromDomainToLeafletFormat(this.alertInfo.multiPolygon);
            this.predefinedArea = converted;
          }
        }
      }
    },
  },
  created() {
    this.alertId = this.$route.params['alertId'];
  },
  mounted() {
    this.goTo({ lat: 60.35, lng: 5.25 });
    // TODO: Get alert from db/store to show in info comp
    // Get latest updated numbers for statuses (from db or operators?)
    this.getAlertInfo(this.alertId);
  },
  beforeDestroy() {
    this.$store.dispatch('alertStatus/reset');
  },
  methods: {
    mapReady() {
      this.mapIsReady = true;
      if (this.alertInfo) {
        let converted = convertFromDomainToLeafletFormat(this.alertInfo.multiPolygon);
        this.predefinedArea = converted;
      }
    },
    goTo(latLng) {
      this.center = latLng;
    },
    operatorLogo: function (operator) {
      operator = operator.toLowerCase();
      if (operator === 'telenor') return require(`@/assets/images/telenor.png`);
      if (operator === 'telia') return require(`@/assets/images/telia.png`);
      if (operator === 'ICE') return require(`@/assets/images/ice.svg`);

      return '';
    },
    getAlertInfo(alertId) {
      this.loading = true;
      return this.$store
        .dispatch('alertStatus/getInfo', alertId)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          alert(error);
          this.loading = false;
        });
    },

    navigateBack() {
      this.$router.push({ name: 'AlertLog' });
    },
  },
};
</script>

<style lang="scss" scoped>
.full-height-row {
  height: calc(100% - 64px);
}
.sending-mode-badge {
  padding: 5px 16px;
  text-align: center;
  border-radius: 19px;
  max-width: 300px;
  height: 35px;
  margin-right: -8px;

  &.status-live {
    background-color: #f7cccc;
    border: 1px solid #ef9a9a;
    color: #670303;
  }

  &.status-simulate {
    background-color: #ffdfb8;
    border: 1px solid #ffc175;
    color: #703e00;
  }
}

.missing-cell-info {
  bottom: 8px;
  position: absolute;
  max-width: 400px;
  background-color: #e5f5ff;
  left: 8px;
  z-index: 200;
  border-radius: 8px !important;
  padding: 8px;
  border: #457b9d 1px solid;
  &.privacy-violation {
    background-color: #fff1e1;
  }
}
.text-simulate-dark {
  color: #703e00;
}
.text-live-dark {
  color: #670303;
}
</style>
